/********************
**---- FUENTES ----**
********************/
@font-face {
  font-family: "Source Sans";
  src: url("../assets/fonts/NeulisAlt-ExtraLight.otf")
    format("truetype-variations");
  font-weight: 100;
}
@font-face {
  font-family: "Source Sans";
  src: url("../assets/fonts/NeulisAlt-Light.otf") format("truetype-variations");
  font-weight: 200;
}
@font-face {
  font-family: "Source Sans";
  src: url("../assets/fonts/NeulisAlt-Thin.otf") format("truetype-variations");
  font-weight: 300;
}
@font-face {
  font-family: "Source Sans";
  src: url("../assets/fonts/NeulisAlt-Medium.otf") format("truetype-variations");
  font-weight: 400;
}
@font-face {
  font-family: "Source Sans";
  src: url("../assets/fonts/NeulisAlt-SemiBold.otf")
    format("truetype-variations");
  font-weight: 500;
}
@font-face {
  font-family: "Source Sans";
  src: url("../assets/fonts/NeulisAlt-Bold.otf") format("truetype-variations");
  font-weight: 600;
}

/*Italic*/
@font-face {
  font-family: "Source Sans";
  src: url("../assets/fonts/NeulisAlt-ExtraLightItalic.otf")
    format("truetype-variations");
  font-style: italic;
  font-weight: 100;
}
@font-face {
  font-family: "Source Sans";
  src: url("../assets/fonts/NeulisAlt-LightItalic.otf")
    format("truetype-variations");
  font-style: italic;
  font-weight: 200;
}
@font-face {
  font-family: "Source Sans";
  src: url("../assets/fonts/NeulisAlt-Italic.otf") format("truetype-variations");
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: "Source Sans";
  src: url("../assets/fonts/NeulisAlt-MediumItalic.otf")
    format("truetype-variations");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "Source Sans";
  src: url("../assets/fonts/NeulisAlt-SemiBoldItalic.otf")
    format("truetype-variations");
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: "Source Sans";
  src: url("../assets/fonts/Neulis-BoldItalic.otf")
    format("truetype-variations");
  font-style: italic;
  font-weight: 600;
}

/********************
**---- CSS GLOBAL ----**
********************/
@import url("../navbar/navbar.css");
@import url("../utils/logo.css");
:root {
  /*Colors*/
  --white: #ffffff;
  --basic-green: #87cb7e;
  --grey: #707070;
  --light-grey: #898ea5;
  --black: #000000;
  --plyr-color-main: #87cb7e;
  --plyr-video-background: white;
}

html,
body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font: 16px "Source Sans", sans-serif;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.overflow {
  overflow: hidden;
}
.section {
  width: 100%;
  height: 100vh;
}

/*--- Pantalla verde ---*/
#first-green-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50px;
  background-color: var(--basic-green);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
  gap: 40px;
}

#logo {
  width: 100%;
}

#logo-letras {
  width: 100%;
}
