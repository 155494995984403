/*-----------------------------------------------------------------------*/
.navbar,
.navbar * {
  box-sizing: border-box;
}
.navbar > header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 75px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  padding: 20px;
  z-index: 700;
}

.navbarButton {
  cursor: pointer;
  height: 100%;
  aspect-ratio: 1/1;
}
.corner-logo-img {
  height: 100%;
  width: 200px;
  background-image: url(../assets/logo-verde.svg);
  background-size: cover;
}

.fullscreen-menu {
  position: fixed;
  right: 0;
  top: 0;
  display: grid;
  justify-content: right;
  align-content: center;
  width: 100%;
  height: 100%;
  padding: 140px;
  gap: 70px;
  color: white;
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 200;
  z-index: 500;
}
#canvas-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;
  width: 50vw;
  height: 100vh;
  z-index: -1;
}
#canvas-menu {
  /* height: 100vw; */
  transform: rotate(-90deg);
}

.link.active {
  font-weight: bold;
}

.fullscreen-menu a {
  text-decoration: none;
  color: white;
}

.background-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: -2;
  opacity: 100%;
}

.mandala-menu {
  position: fixed;
  left: 5%;
  top: -50%;
  opacity: 5%;
  width: 100%;
}

/*------------------------------*
/*-------MEDIA QUERIES----------*
/*------------------------------*/

@media (max-width: 800px) {
  .navbar > header {
    position: fixed;
    height: 50px;
    padding: 10px;
  }

  .corner-logo-img {
    width: 180px;
    height: 35px;
  }

  #canvas-wrapper {
    /* position: absolute;
		display: flex; */
    align-items: center;
    justify-content: center;
    top: 0px;
    right: 0px;
    /* left: 0px; */
    bottom: 0px;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }

  #canvas-menu {
    width: 100%;
    height: 100%;
    transform: scaleY(-1);
  }

  .fullscreen-menu {
    position: fixed;
    right: unset;
    justify-content: center;
    text-align: center;
    font-size: 1.8rem;
    gap: 50px;
  }
}
